import React, { Component } from 'react';
import { graphql, useStaticQuery } from 'gatsby'
import { get } from 'lodash'
import { isExternalUrlOrHash, castToInternalShopifyId, extractStructuredDataJson, isHighSpeedConnection } from '../../helpers.js'
import { YOTPO_WIDGETS, MODEL_PRODUCT } from '../../constants.js'
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner'

class YotpoWidget extends Component {
  constructor(props) {
    super(props);
    this.insertScriptOrUpdate = this.insertScriptOrUpdate.bind(this)
    this.renderProductWidget = this.renderProductWidget.bind(this)
    this.renderSiteWidget = this.renderSiteWidget.bind(this)
    this.scriptId = 'yotpo-script'
    this.scriptLoaded = false;
  }
  componentDidMount() {
    this.insertScriptOrUpdate()
  }
  componentDidUpdate() {
    this.insertScriptOrUpdate()
  }
  
  /**
   * Inserts or Update yotpo script
   * @return {void}
   */
  insertScriptOrUpdate() {
    const idYotpo = get(this.props, 'data.site.siteMetadata.idYotpo')
    const timeoutDelay = 0 //isHighSpeedConnection() ? 3000 : 10000;
    if (this.scriptLoaded) {
      if (window.yotpo) {
        //Means the script was already added
        try {
          (new Yotpo.API(yotpo)).refreshWidgets()
        } catch (e) {
          // Do nothin here, we just want to avoid a possible issue
        }
      } else {
        // Will wait one second before trying again in case the script didn't load yet
        setTimeout(this.insertScriptOrUpdate, 1000)
      }
    } else {
      // We'll load the script after 5 seconds just to make sure this doesn't interfer with page load time
      this.scriptLoaded = true
      if (!document.getElementById(this.scriptId)) {
        // Meaning the script didn't load (for this particular component) and it didn't load yet overall either
        setTimeout((() => {
          // Means it wasn't added
          const script = document.createElement('script')
          script.src = `https://staticw2.yotpo.com/${idYotpo}/widget.js`
          script.setAttribute('id', this.scriptId)
          script.setAttribute('defer', true)
          document.head.append(script)
        }).bind(this), timeoutDelay)
      } else {
        setTimeout(this.insertScriptOrUpdate, 500)
      }
    }
  }
  
  /**
   * Renders YOTPO widget for products
   * @return {HTMLElement}
   */
  renderProductWidget() {
    let {className, productId, productName, productUrl, productDescription, productPrice, productImage, loaderClassName} = this.props
    if (productUrl && !isExternalUrlOrHash(productUrl)) {
      productUrl = get(this.props, 'data.site.siteMetadata.siteUrl') + '/' + productUrl.replace(/^\//, '')
    }
    
    productId = castToInternalShopifyId(productId);
    
    let productAttrs = {
      'data-product-id': productId,
    }
    
    if (productName) {
      productAttrs['data-name'] = productName
    }
    if (productUrl) {
      productAttrs['data-url'] = productUrl
    }
    if (productDescription) {
      productAttrs['data-description'] = productDescription
    }
    if (productPrice) {
      productAttrs['data-price'] = productPrice
    }
    if (productImage) {
      productAttrs['data-image-url'] = productImage
    }
    
    return (
      <div 
        className={`yotpo ${className ? className : ''}`}
        { ...productAttrs }>
          <div className={loaderClassName || ''}>            
            <Spinner animation="border" role="status" size='sm'><span className="sr-only">Loading...</span></Spinner>
            <span className="ml-2 mt-2 font-12">Loading Reviews</span>
          </div>
          {this.props.children}
        </div>
    )
  }
  
  /**
   * Renders YOTPO widget for site reviews
   * @return {HTMLElement}
   */
  renderSiteWidget() {
    return (
      <div id='yotpo-testimonials-custom-tab'>
        {this.props.children}
      </div>
    )
  }
  
  render() {
    switch (this.props.type) {
      case YOTPO_WIDGETS.SITE: return this.renderSiteWidget()
      case YOTPO_WIDGETS.PRODUCT: return this.renderProductWidget()
    }
  };
}


YotpoWidget.defaultProps = {
  productId: '',
  type: YOTPO_WIDGETS.PRODUCT,
  richSnippet: false.PRODUCT,
}

export default (props) => {  
  const data = useStaticQuery(graphql`
    query YotpoWidgetQuery {
      site {
        siteMetadata {
          idYotpo
          siteUrl
        }
      }
    }
  `)
  return (
    <YotpoWidget  {...props} data={data}/>
  )
};