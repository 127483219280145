import React from 'react'
import { Link } from 'gatsby'
import { get } from 'lodash'
import Img from "gatsby-image"
import styles from './product-tile.scss'
import YotpoWidget from '../yotpo/widget'
import { productUrl, castToInternalShopifyId, triggerEvent } from "../../helpers"
import { connect } from 'react-redux'
import { addToCart } from '../cart/actions'
import { SALE_TYPE_STRAIGHT, SALE_TYPE_SUBSCRIPTION } from "../../constants.js"
import ReviewStars from '../review-stars/review-stars'

class ProductTile extends React.Component {
  constructor(props) {
    super(props)
    this.handleVariantChange = this.handleVariantChange.bind(this)
    this.addToCart = this.addToCart.bind(this)
    this.readMoreClickHandler = this.readMoreClickHandler.bind(this)
    this.state = {
      prevLocation: ''
    }
  }
  
  componentDidMount() {
    this.setState({
      prevLocation: window.location.pathname
    })
  }
  
  /**
   * Handles select change
   * @param  {Event} e
   * @return {void}
   */
  handleVariantChange(e) {
    const { currentTarget: { value } } = e
    if (value >= 0) {
      this.addToCart(e, value)
    }
    e.currentTarget.value = -1
  }
  
  /**
   * Adds product to cart
   * @param {Event} e
   * @param {Number} [variantIndex=0]
   */
  addToCart(e, variantIndex = 0) {
    const productData = {
      product: {
        ...this.props.product,
        title: this.props.subscriptionProduct ? this.props.subscriptionProduct.title : this.props.product.title
      },
      variant: this.props.subscriptionProduct ? this.props.subscriptionProduct.variants[variantIndex] : this.props.product.variants[variantIndex],
      variantIndex,
      qty: 1
    }
    if (this.props.subscriptionProduct) {
      productData[SALE_TYPE_SUBSCRIPTION] = true
    }
    this.props.dispatch(addToCart(productData))
  }
  
  /**
   * Event triggered when "read more" is clicked
   * @param  {Event} e
   * @return {void}
   */
  readMoreClickHandler(e) {
    const product = {
      // id: castToInternalShopifyId(this.props.product.id),
      id: this.props.product.variants[0].sku,
      name: this.props.product.title,
      price: this.props.product.variants[0].price,
      category: this.props.product.productType,
      quantity: 1
    }
    
    triggerEvent('product_click', {
      ecommerce: {
        currencyCode: 'USD',
        click: {
          products: [
            product
          ]
        }
      }
    })
  }
  
  render() {
    const product = this.props.product
    const subscriptionProduct = this.props.subscriptionProduct
    const productData = subscriptionProduct || product
    const btnClass = this.props.btnClass || 'btn-primary'
    const productShopBtn = this.props.productShopBtn || 'product-shop-btn'
    const btnStyle = this.props.btnStyle || {}
    const productLink = productUrl(product)
    const dataAttrs = this.props.dataAttributes || {}
    let imgAttrs = {
      alt: get(product, 'images[0].altText') || product.handle,
      loading: "lazy",
      ...dataAttrs
    }
    if (this.props.useImageSrc) {
      imgAttrs.src = product.images[0].transformedSrc || product.images[0].src
      imgAttrs.className = 'img-fluid product-image lazyload'
    } else {
      const file = get(product, 'images[0].localFile.childImageSharp', {})
      if (file.fluid) {
        imgAttrs.fluid =  file.fluid
      } else if (file.fixed) {
        imgAttrs.fixed =  file.fixed
      }
      imgAttrs.className = 'product-image'
      imgAttrs.objectFit = 'cover'
    }
    
    const productLinkState = subscriptionProduct ? {
      state: {
        prevPath: this.state.prevLocation
      }
    } : {}
    
    return (
      <div className="card card-product">
        {this.props.productPageDesign && (
          <div className="card-header">
            <Link
              to={productLink}
              className="product-image-container"
              title="View Product Details"
              { ...productLinkState }>
              { imgAttrs.src &&  <img { ...imgAttrs }  /> }
              { (imgAttrs.fluid || imgAttrs.fixed) &&  <Img { ...imgAttrs } />}
            </Link>
          </div>
        )}
        <div className={`product-info card-body ${this.props.containerClassName ? this.props.containerClassName : ''}`}>
          {!this.props.productPageDesign && (
            <Link
              to={productLink}
              className="product-image-container"
              title="View Product Details"
              { ...productLinkState }>
              { imgAttrs.src &&  <img { ...imgAttrs }  /> }
              { (imgAttrs.fluid || imgAttrs.fixed) &&  <Img { ...imgAttrs } />}
            </Link>
          )}
          <div className="product-title card-title text-primary "><Link to={productLink} {...productLinkState}>{productData.title}</Link></div>
          {/*<div className="product-reviews-container">            */}
          {/*</div>*/}
          { product.reviews && this.props.showReviews && (
            <ReviewStars stars={ get(product, 'reviews.bottomline.average_score', 0) }
            reviewsNumber={get(product, 'reviews.bottomline.total_review', 0)}
            className={this.props.reviewStarsClassName ? this.props.reviewStarsClassName : ''}
            color="#E8CA2C" />
          )}
          {/* <div className="product-reviews-container">         
            <YotpoWidget className='stars-container bottomLine' productId={product.id} productName={product.title}
              productUrl={productLink} />
          </div> */}
          <p className="product-price font-weight-bold mb-2 mt-2">${productData.variants[0].price}</p>
          { productData.discountCodeText ? (
            <div className="text-danger text-uppercase my-2 my-md-1 font-weight-bold font-14">{productData.discountCodeText}</div>
          ) : '' }
          { product.ownRelatedProductDescription && (
            <ul className="own-related-product-description text-left">
              <li>{product.ownRelatedProductDescription}</li>
            </ul>
          )}
          <div className={`${productShopBtn} d-flex align-items-end justify-content-center`}>
            {productData.variants.length > 1 ? (
              <select title={this.props.shopOptionLabel} name={`${product.handle}-variants`} className={`btn ${btnClass} btn-shop ${this.props.useBlockButton ? 'btn-block' : ''}`} onChange={this.handleVariantChange} style={btnStyle}>
                <option value='-1'>{this.props.shopOptionLabel}</option>
                {
                  productData.variants.map((variant, index) => (
                    <option value={index} key={index} disabled={!variant.availableForSale} >
                      { `${variant.title} - $${variant.price} ${!variant.availableForSale ? '(Temporarily Out of Stock)' : ''}` } 
                    </option>
                  ))
                }
              </select>
            ) : (
              productData.variants[0].availableForSale ? (
                <button  className={`btn ${btnClass} btn-shop ${this.props.useBlockButton ? 'btn-block' : ''}`} onClick={this.addToCart} style={btnStyle} title={this.props.shopLabel}>{this.props.shopLabel}</button>
              ) : (
                <button  className={`btn btn-gray btn-shop ${this.props.useBlockButton ? 'btn-block' : ''}`} disabled title="Out Of Stock">Out Of Stock</button>
              )
            )}
          </div>
          {this.props.readMoreLink ? (
            <div className="product-readmore-link">
              <Link 
                to={productLink} 
                className="btn btn-link" 
                title="View Product's Details"
                onClick={this.readMoreClickHandler}
                { ...productLinkState }
                { ...dataAttrs }>View this product</Link>
            </div>
          ) : ''}
        </div>
      </div>
    )
  }
}

ProductTile.defaultProps = {
  shopLabel: 'Add to Cart',
  shopOptionLabel: 'Choose Weight',
  showReviews: true,
  readMoreLink: false,
  useImageSrc: false,
  useBlockButton: true,
  productPageDesign: false
}

export default connect()(ProductTile)
