import React from 'react'
import { Link } from 'gatsby'
import ProductTile from '../product-tile/product-tile'
import styles from './related-products.scss'
import { productUrl, htmlFromText } from '../../helpers.js'

class RelatedProducts extends React.Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    return (      
      <section className={`related-products text-center ${this.props.className ? this.props.className : ''}`}>
        <div className="container">
          <h2 dangerouslySetInnerHTML={htmlFromText(this.props.title, ['br'], {br: ['class']})}></h2>
          <div className="row products">
            {
              this.props.products.map((product, index) => {
                let containerClassName = 'product-tile-container col-12 col-sm-10 col-md-6 col-lg-4 col-xl-4 offset-sm-1 offset-md-0 mb-md-4'
                if (index > 0) {
                  containerClassName += ' mt-4 mt-md-0'
                }
                if (index > 1) {
                  containerClassName += ' mb-lg-0'
                }
                return (
                  <div className={containerClassName} key={index}>
                    <ProductTile product={product} 
                      readMoreLink={this.props.readMoreLink} 
                      shopLabel={this.props.shopLabel} 
                      useImageSrc={this.props.useImageSrc}
                      useBlockButton={this.props.useBlockButton} 
                      dataAttributes={{
                        'data-event-trigger': 'click',
                        'data-event-name': 'Recommendation Clicked',
                        'data-event-cta': 'You May Also Like',
                        'data-event-category': 'direct-link',
                        'data-event-uri': productUrl(product),
                      }}/>
                  </div>
                )                  
              })
            }              
          </div>
          <Link to="/collections/" className={`btn ${this.props.btnClassName ? this.props.btnClassName : 'btn-primary btn-medium'}`}>Shop Our Products</Link>
        </div>
      </section>
    )
  }
}

RelatedProducts.defaultProps = {
  shopLabel: 'Add to Cart',
  readMoreLink: false,
  useImageSrc: false,
  title: 'You May Also Like',
  useBlockButton: true,
}

export default RelatedProducts
